import React, { useState } from "react";
import parse from "html-react-parser";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import dataCounters from "../../data/Counters/counter-data2b.json";

const FunFactsArchitecture2 = ({ title, tagline, paragraph1, paragraph2, children }) => {
  const [viewed, setViewed] = useState(false);

  const viewChangeHandler = (isVisible) => {
    if (isVisible) setViewed(true);
  };

  return (
    <section
      className="parallax-bg-17b fixed-bg fact-section text-center"
      data-stellar-background-ratio="0.2"
    >
   <div className="col-md-2"> <h4
              className="text-uppercase"
              data-aos={"fade-right"}
              data-aos-delay={100}
              data-aos-duration={700}
            >
              
            </h4></div>
      <div className="overlay-bg text-center"></div>
      <div className="container">
        <div className="row white-color">
          <div className="col-md-2">
            <h1
              className="text-uppercase font-700"
              data-aos={"fade-right"}
              data-aos-delay={100}
              data-aos-duration={700}
            >
              {title}
            </h1>
            <h4
              className="text-uppercase"
              data-aos={"fade-right"}
              data-aos-delay={100}
              data-aos-duration={700}
            >
              - {tagline} -
              
            </h4>
          </div>
          <div className="col-md-8">
           {paragraph1} 
           <h4
              className="text-uppercase"
              data-aos={"fade-right"}
              data-aos-delay={100}
              data-aos-duration={700}
            >
               {paragraph2} 
              
            </h4>
            <div className="mt-15"
                data-aos={"fade-up"}
                data-aos-delay={200}
                data-aos-duration={700}
                data-aos-class={"hard-break"}
              >
                {children ? parse(children) : ""}
            
            </div>
          </div>
        </div>
        <div className="row mt-30 ">
          <div className="col-md-8"> </div>
          <div className="col-md-12 ">
            <div className="row">
              {dataCounters
                .filter((v, i) => i < 5)
                .map((counter, i) => (
                  <div
                    className="col-md-4 counter col-sm-8 text-center"
                    key={counter.id}
                    data-aos={"fade-up"}
                    data-aos-delay={`${i}00`}
                    data-aos-duration={700}
                  >
                    <h1 className="count white-color font-700 text-center">
                    {counter.prefix} <VisibilitySensor
                        onChange={viewChangeHandler}
                        delayedCall
                      >
                        <CountUp end={viewed ? counter.value : 0} />
                      </VisibilitySensor>
                    </h1>
                    <h3 className="text-left">{counter.title}</h3>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFactsArchitecture2;
