import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import FunFactsArchitecture2 from "../../components/FunFacts/FunFactsArchitecture2";
import GalleryArchitecture from "../../components/Gallery/GalleryArchitecture";
import ContactArchitecture from "../../components/ContactUs/ContactArchitecture";
import FooterOne from "../../components/Footer/FooterOne";
import CTAOne from "../../components/CTA/CTAOne";




const Architecture = () => {
  const home = useRef();
  const about = useRef();
  const sales = useRef();
  const service = useRef();
  const agents = useRef(); 
  const rentals = useRef();
  const contact = useRef();

  const scrollToSection = (e, content) => {
    e.preventDefault();
    switch (content) {
      case "home":
        home.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
          break;
      case "sales":
        sales.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "service":
        service.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "agents":
        agents.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "rentals":
        rentals.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contact.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderOne scrollToSection={scrollToSection} />
     
      <h2>CURRENT SALES</h2><br />
      
    

      <GalleryArchitecture
        title="PROPERTIES FOR SALE"
        tagline="Currently ON OFFER"
        classAppend="mt-150 pb-50"
        ref={sales}
      />

<FunFactsArchitecture2 title="2023" tagline="By The Numbers" paragraph1="In 2022, the Manhattan luxury market has turned in a solid performance: 1,304 contracts were signed at $4 million and above, totaling over $10,320,149,800. It was also a year that was front-loaded: 836 contracts were signed in the first half of the year compared with 468 in the 2nd half, as the market deteriorated under the weight of spiking Interest rates and a declining stock market." paragraph2="Here is how 2023 is shaping up so far:" />


      

      

      <ContactArchitecture
        title="CONTACT OLSHAN"
        tagline="Connect to Your New Home"
        classAppend="mt-50"
        ref={contact}
      />

<CTAOne
        tagline="Olshan Luxury Report"
        title="Highly focused. Driven by Data."
        textButton="GET THE LATEST LUXURY REPORT"
        textLink="marketreport"
        bg="color"
      >
        This report is the only one of its kind. On a weekly basis, Olshan publishes a report covering all the contract activity in the luxury market on properties at $4 million and above. The free report draws subscribers from all over the world, and is frequently quoted in the New York Times, Wall Street Journal, Crain's, Observer, The Real Deal and various media outlets. 
      </CTAOne>
      <FooterOne />
    </Loader>
  );
};



export default Architecture;
