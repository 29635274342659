import React, { useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import dataArchitecture from "../../data/Slider/architecture-data.json";
import dataAccordion from "../../data/Accordion/accordions-data.json";
import Loader from "./../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";
import ArchitectureSlider from "../../components/Hero/ArchitectureSlider";
import AccordionsComponent from "../../components/Accordions/AccordionsComponent";
import AboutArchitecture from "../../components/AboutUs/AboutArchitecture";
import FunFactsArchitecture from "../../components/FunFacts/FunFactsArchitecture";
import ServicesArchitecture from "../../components/OurServices/ServicesArchitecture";
import TeamArchitecture from "../../components/Team/TeamArchitecture";
import GalleryArchitecture from "../../components/Gallery/GalleryArchitecture";
import ContactArchitecture from "../../components/ContactUs/ContactArchitecture";
import FooterOne from "../../components/Footer/FooterOne";

import CTAOne from "../../components/CTA/CTAOne";




const Architecture = () => {
  const home = useRef();
  const about = useRef();
  const sales = useRef();
  const service = useRef();
  const agents = useRef(); 
  const rentals = useRef();
  const contact = useRef();

  const scrollToSection = (e, content) => {
    e.preventDefault();
    switch (content) {
      case "home":
        home.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "about":
        about.current.scrollIntoView({ behavior: "smooth" });
          break;
      case "sales":
        sales.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "service":
        service.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "agents":
        agents.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "rentals":
        rentals.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contact.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderOne scrollToSection={scrollToSection} />
      <AccordionsComponent type="color" data={dataAccordion} classAppend="mt-120" />


      <ArchitectureSlider data={dataArchitecture} ref={home} />
      <AboutArchitecture   ref={about} classAppend="mt-50" />
      
      <FunFactsArchitecture title="2025" tagline="By The Numbers" paragraph1="Unsurprisingly, the rich got richer: The trophy market saw 278 deals at $10 million and above, 38 more than last year. In trophy sales, 2024 ranked as the 2nd best year since this report was launched in 2006. The top year was 2021---the pandemic rebound year---when 400 trophy properties were sold." paragraph2="Here is how 2025 is shaping up so far:" />

      

      <ServicesArchitecture
        title="OUR SERVICES"
        tagline="What We Do"
        ref={service}
      />

           <GalleryArchitecture
        title="MORE FEATURED PROPERTIES"
        tagline="Currently ON OFFER"
        classAppend="mt-150"
        ref={sales}
      />

     <GalleryArchitecture
        title="RENTAL PROPERTIES"
        tagline="Currently ON OFFER"
        ref={rentals}
      />

      <ContactArchitecture
        title="CONTACT OLSHAN"
        tagline="Connect to Your New Home"
        classAppend="mt-50"
        ref={contact}
      />
      <FooterOne />
    </Loader>
  );
};



export default Architecture;
