import React, { forwardRef } from "react";
import dataTeam from "../../data/Team/agent-architecture.json";
import HeadingSection from "../HeadingSection/HeadingSection";
import { Link } from "react-router-dom";
import Icofont from "react-icofont";

const AgentArchitecture = forwardRef(({ title, tagline }, ref) => (
  <section className="white-bg" id="team" ref={ref}>
    <div className="container">
      <div className="row">
        <HeadingSection title={title} tagline={tagline} />
      </div>
    </div>
    <div className="container">

    <div className="row">
    <div className="col-sm-3 startup-member"> 
    
        {dataTeam.map((member, i) => (
          <div
            
            key={member.id}
            data-aos={"fade-up"}
            data-aos-delay={`${i}00`}
            data-aos-duration={700}
          >
            
              <div className="team-member-container">
                <img
                  src={require("../../assets/images/" + member.image)}
                  className="img-fluid"
                  alt="team-01"
                />
                <div className="member-caption">
                  <div className="member-description text-center">
                    <div className="member-description-wrap">
                      <ul className="member-icons">
                        {member.social.map((item) => (
                          <li className="social-icon" key={item.id}>
                            <a href={item.link}>
                              <Icofont icon={item.icon} />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div> </div>
                <div className="white-bg pt-20 pb-20 box-shadow-hover text-center">
                <h4 className="member-title text-uppercase">CONTACT {member.name}</h4>
               Phone: {member.phone}<br />
               Cell: {member.cell}<br />
               Fax: {member.fax}<br />
               <a href={`mailto:${member.email}`}
          >
            {member.email}
          </a>
              </div>
            
          </div>
          
        ))}
      </div>
      
    
    <div className="col-sm-7">{dataTeam.map((member, i) => (
        <div
            key={member.id}
            data-aos={"fade-up"}
            data-aos-delay={`${i}00`}
            data-aos-duration={700}
          >
        <h4 className="member-title text-uppercase">{member.name}</h4>
                <p className="member-subtitle">{member.role}</p>
                <p className="member-subtitle paras bio-text">{member.bio}</p>

                
                </div>
                ))}</div>


                <div className="col-sm-2"><p><span class="listinghed"><b>CURRENT LISTINGS:</b></span><br />
<span class="listing">
<a href="http://www.olshan.com/property.php?id=22369542">1049 Fifth Avenue 10AB </a><br /><a href="http://www.olshan.com/property.php?id=22295343">1049 Fifth Avenue 10B </a><br /><a href="http://www.olshan.com/property.php?id=20135621">136 East 56th Street 7K </a></span>
<br />
<br /><span class="listinghed"><b>RECENTLY SOLD:</b></span><br />

<span class="listing">
<a href="http://www.olshan.com/property.php?id=173752">101 CPW, 5C/5D </a><br /><a href="http://www.olshan.com/property.php?id=19040">10 Gracie Square 11/12F </a><br /><a href="/">101 Central Park West, 4B</a><br />
<a href="/">40 Bond Street 5C</a><br />
<a href="/">11 Beach Street 7A</a><br />
<a href="/">188 East 78th Street, 24B</a><br />
<a href="h/">300 Central Park West</a><br />
<a href="/">218 East 61st Street</a><br />
<a href="/">641 Fifth Avenue 30CD</a><br />
<a href="/">137 East 66th Street PH9B</a><br />
<a href="/">300 Central Park West</a><br />
<a href="/">101 Warren Street</a><br />
<a href="/">14 West 17th Street 10 FL</a><br />
<a href="/">15 West 81st St.</a><br />
<a href="/">955 Fifth Avenue 4B</a><br />
<a href="/">217 West 19th Street PH11</a><br />
<a href="/">969 Park Avenue</a><br />
<a href="/">524 East 72nd street</a><br />
<a href="/">1080 5th Ave. Apt. 4C</a><br />
<a href="/">11 Fifth Avenue 9DC</a><br /><br />
<a href="/"><b>VIEW COMPLETE LIST &rarr;</b></a><br />

</span>
</p></div></div>
</div>


     
    
  </section>
));

export default AgentArchitecture;
